import "./App.scss";
import React, { useState, useEffect, useRef } from "react";
import Metaballs from "./Metaballs/Metaballs";
import ToolsImage from "./assets/images/tools.png";
import ResponseImage from "./assets/images/response.png";

// Converts a hex color to its RGB components
// const hexToRgb = (hex) => {
//   let r = 0,
//     g = 0,
//     b = 0;
//   // 3 digits
//   if (hex.length === 4) {
//     r = parseInt(hex[1] + hex[1], 16);
//     g = parseInt(hex[2] + hex[2], 16);
//     b = parseInt(hex[3] + hex[3], 16);
//   }
//   // 6 digits
//   else if (hex.length === 7) {
//     r = parseInt(hex[1] + hex[2], 16);
//     g = parseInt(hex[3] + hex[4], 16);
//     b = parseInt(hex[5] + hex[6], 16);
//   }
//   return [r, g, b];
// };

// // Interpolates two RGB color values
// const interpolateColor = (color1, color2, factor) => {
//   if (factor > 1) factor = 1;
//   else if (factor < 0) factor = 0;

//   const result = color1.slice().map((c, i) => {
//     return Math.round(c + factor * (color2[i] - c));
//   });

//   return `rgba(${result[0]}, ${result[1]}, ${result[2]}, 0.452)`;
// };

const Badges = ({ skills = [] }) => {
  return (
    <ul className="badge-container">
      {skills.map((skill, i) => (
        <li key={i} className="skill-badge">
          <span className="badge-label">{skill}</span>
        </li>
      ))}
    </ul>
  );
};

const InlineLink = ({ url, label, theme }) => {
  return (
    <a
      className="inline-link"
      href={url}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={label}
      style={{
        backgroundImage: `linear-gradient(45deg, ${theme.accent1}, ${theme.accent2})`,
      }}
    >
      {label}
    </a>
  );
};

const DividerLine = ({ theme }) => {
  return (
    <div
      style={{
        background: `linear-gradient(45deg, ${theme.accent1}, ${theme.accent2})`,
        height: 1,
        width: "100%",
        marginTop: 32,
        marginBottom: 32,
      }}
    ></div>
  );
};

// const themeDefault = {
//   background1: "#000",
//   background2: "#333",
//   accent1: "#0000FF",
//   accent2: "#FF0000",
// };

const themeDefault = {
  themeName: "Night City Neon",
  accent1: "#ff00ff",
  accent2: "#00ffff",
  bg1: "#0d0d0d",
  bg2: "#1a1a1a",
};

const apiUrl = "https://chatgpt-server-green.vercel.app/api/generate-theme";
//const apiUrl = "http://localhost:3001/api/generate-theme";

function App() {
  const [activeSection, setActiveSection] = useState("about");
  const [colorTheme, setColorTheme] = useState(themeDefault);
  const sectionRefs = useRef([]);
  const navLinks = useRef([]);
  const [hoverLink, setHoverLink] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  //const [error, setError] = useState(null);

  const generateTheme = async () => {
    setIsLoading(true); // Show loading indicator
    // setError(null); // Reset error state

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: inputValue }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();

      console.log(result?.message);

      const jsonTheme =
        result?.message?.tool_calls[0]?.function?.arguments || false;
      if (jsonTheme) {
        const newColorTheme = JSON.parse(jsonTheme);

        setColorTheme(newColorTheme);
        console.log(newColorTheme);
        setInputValue("");
      } else {
        throw new Error(`Error: something happened. Please try again.`);
      }
    } catch (error) {
      console.error(error.message); // Update state with the error message
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRefs.current.find((sec) => {
        const rect = sec?.getBoundingClientRect();
        return (
          rect.top <= window.innerHeight / 3 &&
          rect.bottom >= window.innerHeight / 3
        );
      });
      if (section && activeSection !== section.id) {
        setActiveSection(section.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [activeSection]);

  const addSectionRef = (element) => {
    if (element && !sectionRefs.current.includes(element)) {
      sectionRefs.current.push(element);
    }
  };

  const getUnderlineStyle = (sectionId) => {
    const isActive = activeSection === sectionId;
    const isHovered = hoverLink === sectionId;

    return {
      width: isActive || isHovered ? "128px" : "0",
      background: `linear-gradient(45deg, ${colorTheme.accent1}, ${colorTheme.accent2})`,
      height: "1px",
    };
  };

  const handleSubmit = (event) => {
    // Prevent the default form submit action (page reload)
    event.preventDefault();

    // Your logic here
    generateTheme();
  };

  return (
    <div className="App">
      <div className="background-container">
        <div className="gradient-overlay"></div>
        <div
          className="lavalamp-container"
          style={{
            background: `linear-gradient(170deg, ${colorTheme.bg1}, ${colorTheme.bg2})`,
          }}
        ></div>
      </div>
      <div className="main-container">
        <div className="sticky-sidebar">
          <h2>Eric Gow</h2>
          <h4>
            Web developer, full stack, front end, <br /> and anything else
          </h4>
          <p>I like solving problems and learning new stuff.</p>
          <div style={{ marginTop: 36, display: "flex" }}>
            <nav>
              <ul>
                {["about", "experience", "other"].map((sectionId, index) => (
                  <li
                    onMouseEnter={() => setHoverLink(sectionId)}
                    onMouseLeave={() => setHoverLink(null)}
                    key={sectionId}
                  >
                    <a
                      href={`#${sectionId}`}
                      className={`nav-link ${
                        activeSection === sectionId ? "active" : ""
                      }`}
                      ref={(el) => (navLinks.current[index] = el)}
                    >
                      {`${sectionId[0].toUpperCase() + sectionId.slice(1)}`}
                      <div
                        className="underline"
                        style={getUnderlineStyle(sectionId)}
                      ></div>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>

          <div style={{ marginTop: 24, display: "flex", flexDirection: "row" }}>
            <a
              className="socials linked-in"
              href="https://www.linkedin.com/in/eric-gow-79835a217/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="LinkedIn (opens in a new tab)"
              title="LinkedIn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="url(#grad1)"
                className="socials-svg"
                aria-hidden="true"
              >
                <defs>
                  <linearGradient id="grad1" x1="0%" x2="100%" y1="0%" y2="0%">
                    <stop offset="0%" stopColor={colorTheme.accent1} />
                    <stop offset="100%" stopColor={colorTheme.accent2} />
                  </linearGradient>
                </defs>
                <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
              </svg>
            </a>
            <a
              className="socials"
              href="https://github.com/ecgw"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="GitHub (opens in a new tab)"
              title="GitHub"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="url(#grad1)"
                className="socials-svg"
                aria-hidden="true"
              >
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path>
              </svg>
            </a>
          </div>
          <div className="theme-changer">
            <div
              className="mini-lava-lamp"
              style={{
                background: `linear-gradient(170deg, ${colorTheme.bg1}, ${colorTheme.bg2})`,
              }}
            >
              <Metaballs theme={colorTheme} />
            </div>
            <div>
              <span style={{ display: "inline-block", marginTop: 6 }}>
                {colorTheme.themeName}
                <div
                  style={{
                    width: 10,
                    height: 10,
                    marginLeft: 12,
                    backgroundColor: colorTheme.bg1,
                    display: "inline-block",
                  }}
                ></div>
                <div
                  style={{
                    width: 10,
                    height: 10,
                    backgroundColor: colorTheme.bg2,
                    display: "inline-block",
                  }}
                ></div>
                <div
                  style={{
                    width: 10,
                    height: 10,

                    backgroundColor: colorTheme.accent1,
                    display: "inline-block",
                  }}
                ></div>
                <div
                  style={{
                    width: 10,
                    height: 10,

                    backgroundColor: colorTheme.accent2,
                    display: "inline-block",
                  }}
                ></div>
              </span>
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      zIndex: 2,
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: "rgb(22, 22, 22)",
                      display: isLoading ? "flex" : "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="loader"
                      style={{
                        background: `linear-gradient(${colorTheme.bg1}, ${colorTheme.bg2}, ${colorTheme.accent1}, ${colorTheme.accent2})`,
                      }}
                    ></div>
                  </div>
                  <input
                    placeholder="Enter a color, emotion, food, anything."
                    type="text"
                    disabled={isLoading}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    maxLength={150}
                    className="theme-prompt"
                  />
                </div>

                <button
                  type="submit"
                  disabled={inputValue.length === 0 || isLoading}
                  className="theme-button"
                >
                  {inputValue.length === 0
                    ? "Change theme"
                    : isLoading
                    ? `Generating...`
                    : `Change theme`}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="main-content">
          <section id="about" ref={addSectionRef} className={"about"}>
            <p>
              In 2019 I wanted to try something new and challenging. On a whim I
              signed up for a coding bootcamp, which drowned me in the world of
              full-stack web and mobile development. It was a difficult course
              but also very rewarding, and I soon felt right at home. After the
              program I was hired by the school's agency and started doing work
              for real clients. My first big project was a mobile app and
              backend service to help streamline the{" "}
              <InlineLink
                url={"https://facturation.net/"}
                label={"medical billing process"}
                theme={colorTheme}
              />{" "}
              for healthcare professionals in Quebec.
            </p>
            <p>
              Since then, I've worked on an app for a{" "}
              <InlineLink
                url={"https://votreconseiller.net"}
                label={"financial services firm"}
                theme={colorTheme}
              />
              , and a service that helps people{" "}
              <InlineLink
                url="https://moovejob.com/accueil"
                label="find jobs abroad"
                theme={colorTheme}
              />
              . Most recently I was front-end developer for an art marketplace
              startup.
            </p>

            <p>
              When I'm not at the computer, I enjoy practicing the piano, doing
              escape rooms, or making my own beef jerky.
            </p>
          </section>

          <section id="experience" ref={addSectionRef} className="experience">
            <a
              href="https://xverso.io"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="art marketplace"
              className="experience-link"
            >
              <div style={{ width: "20%" }}>
                <span>2021 - 2023</span>
              </div>
              <div style={{ marginLeft: 24, width: "80%" }}>
                <span>Front-end Developer</span>
                <br />
                <span>XVERSO</span>
                <br />
                <p style={{ fontSize: "1rem" }}>
                  Front-end/Mobile Developer within a small team of three at an
                  art community and marketplace. My role went far beyond
                  traditional development, reflecting the dynamic startup
                  environment where adaptability and creativity were paramount.
                  My contributions spanned from crafting mockups and UI designs
                  to actively shaping the product's evolution in regular
                  strategy meetings. I also extended my expertise to multimedia,
                  handling video editing and streaming, as well as creating
                  engaging 3D graphics.
                </p>
                <Badges
                  skills={[
                    "JavaScript",
                    "TypeScript",
                    "HTML & SCSS",
                    "React",
                    "Next.js",
                    "Figma",
                  ]}
                />
              </div>
            </a>
            <a
              href="https://codeboxx.biz"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="codeboxx"
              className="experience-link"
            >
              <div style={{ width: "20%" }}>
                <span>2019 - 2021</span>
              </div>
              <div style={{ marginLeft: 24, width: "80%" }}>
                <span>Full Stack Developer</span>
                <br />
                <span>Codeboxx</span>
                <br />
                <p style={{ fontSize: "1rem" }}>
                  Developed, maintained and shipped production code for multiple
                  clients, including:
                </p>
                <ul
                  style={{
                    paddingLeft: 0,
                    fontSize: "1rem",
                    lineHeight: "2rem",
                  }}
                >
                  <li>
                    A mobile application for a financial services firm, designed
                    to generate insurance quotes and assess investment
                    requirements for clients, turning a complex process into a
                    user-friendly experience.
                  </li>
                  <li
                    style={{
                      marginBottom: 12,
                      lineHeight: "1rem",
                      listStyleType: "none",
                      marginTop: 6,
                    }}
                  >
                    <Badges skills={["React Native", "JavaScript", "SCSS"]} />
                  </li>
                  <li>
                    A full-stack solution made specifically for healthcare
                    professionals, comprising a mobile app and backend API and
                    database. The system facilitated connectivity with the
                    Government of Quebec's legacy SOAP API for the submission of
                    billing documents. It enabled efficient tracking of rendered
                    services, ensuring accurate billing to government entities.
                    The project also required an extensive analysis of medical
                    billing regulations.
                  </li>
                  <li
                    style={{
                      marginBottom: 12,
                      lineHeight: "1rem",
                      listStyleType: "none",
                      marginTop: 6,
                    }}
                  >
                    <Badges
                      skills={[
                        "React Native",
                        "JavaScript",
                        "SCSS",
                        "ASP.NET",
                        "C#",
                        "SQL",
                        "SOAP",
                        "XML",
                      ]}
                    />
                  </li>
                  <li>
                    Backend service and algorithm for a dynamic matchmaking
                    platform, facilitating connections between international job
                    seekers and prospective employers.
                  </li>
                  <li
                    style={{
                      marginBottom: 12,
                      lineHeight: "1rem",
                      listStyleType: "none",
                      marginTop: 6,
                    }}
                  >
                    <Badges skills={["Ruby on Rails", "MVC", "SQL"]} />
                  </li>
                </ul>
              </div>
            </a>
            <div className="inner-padding">
              <p>
                I also have diverse (and unique) other experience spanning
                multiple industries. Much of it is culinary, ranging from fine
                dining restaurants and catering services to large-scale
                industrial food production and packaging. I also worked in a few
                warehouses, both food and non-food related, and got pretty darn
                good at driving forklifts.
              </p>
              <p>
                I've also done work as a security guard, as the consierge of a
                fancy condo in Toronto. I've cleaned hotel rooms and loaded
                machines for a printing press, tried my hand at cabinet making
                for a summer, and assisted with the liquidation process at
                Target as the company exited Canada. Among all of my varied
                experiences, probably the most fun and my favorite position was
                as a haunted house actor.
              </p>
              <p>
                This diverse range of experience not only demonstrates my
                ability to adapt and excel in various settings, but also my
                commitment to bringing enthusiasm and dedication to every
                opportunity.
              </p>
            </div>
          </section>

          <section id="other" ref={addSectionRef} className="other">
            <h2>
              What's this{" "}
              <span
                style={{
                  backgroundImage: `linear-gradient(45deg, ${colorTheme.accent1}, ${colorTheme.accent2})`,
                  backgroundClip: "text",
                  color: "transparent",
                }}
              >
                change theme
              </span>{" "}
              thing?
            </h2>
            <p>
              Try it! It's a small project I did with the ChatGPT API. I liked
              the idea of generating themes just by describing them, and wanted
              to see how well the basic model would do. I used{" "}
              <code>gpt-3.5-turbo</code> and it does a pretty good job.
            </p>

            <p>
              The project helped answer a question I had about how AI interacts
              with other systems. Of course chatting with the language model is
              really cool, but what else can we do with it? And more
              specifically, <b>how?</b>
            </p>
            <p>
              <i>
                How can I actually use it to do more than just chat back and
                forth?
              </i>
            </p>
            <p>
              Because at some point it has to go from responding with text to
              actually making meaningful changes to a system. It has to "reach
              out and grab" stuff and not just write an essay. How would an AI
              actually interact with the bare metal of the computer if it were
              integrated as its operating system?
            </p>
            <p>
              I heard that ChatGPT can be used as the brain of electronic pets.
              I guess you basically give it a system prompt like: "You are a
              dog, you do dog things" and it'd just mimic a dog and somehow
              control the robot dog body.
            </p>
            <p>
              In concept sure it makes sense but <i>how</i> exactly do you go
              from a chat response to moving robot body parts? How can ChatGPT's
              text-based language model make that physical robot dog's tail wag?
            </p>
            <DividerLine theme={colorTheme} />
            <p>
              The answer, at least between ChatGPT and a webserver, is by using
              the{" "}
              <InlineLink
                url={"https://platform.openai.com/docs/guides/function-calling"}
                label={"function calling"}
                theme={colorTheme}
              />{" "}
              feature. You define what "tools" it has available (your
              functions), and if it determines one of them should be called, it
              gives you the arguments in JSON along with the name, which you
              then parse on your server and invoke yourself. I guess the AI
              doesn't really "do" much, at least not in the way I imagined. It's
              still essentially just a language model returning a chat response,
              albeit forced into a specific format.
            </p>
            <p>
              For the system prompt, I had to be quite specific with it. It was
              almost like programming in a way, but not. Prompt-gramming. For
              example it was giving me bad colors for "obvious" themes, such as
              Canada, where I expected only red and white. Asking for 4 colors
              seemed to make it choose some at random to fill up the slots, even
              if it didn't fit the theme. I had to explain that it was okay to
              only pick 2 different colors if that's what best fit the theme.
              Just make the other 2 colors as tints of the first 2 and it's
              fine. That sort of thing. The more examples I gave and the more
              specific I was about how it should pick the colors I ended up
              getting better results.
            </p>
            <p>
              The <code>tools</code> property is pretty simple: You define your
              functions, describe what they do and the arguments they expect,
              and it gets included with your system and user prompts.{" "}
            </p>
            <img
              src={ToolsImage}
              alt="the tools object"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: 12,
              }}
            />
            <p>
              If the AI determines through context that it should call one of
              the functions you provided (in my case a function called{" "}
              <code>change_theme</code>), it will respond with it's name and
              required arguments:
            </p>
            <img
              src={ResponseImage}
              alt="the response"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: 12,
              }}
            />
            <p>
              From there, I just plug the colors into the theme variables and
              voila!
            </p>
            <DividerLine theme={colorTheme} />

            <h2>Where does it go from here?</h2>

            <p>
              I wonder how AIs will be used in the future. They will basically
              become an interface between humans and machines. That we will
              essentially just ask it to do something for us on the computer and
              it will. We will be able to talk to our systems and data with
              natural language.
            </p>
            <p>
              The speed of AI development is absolutely insane, it seems like
              every day there is a new breakthrough. We've barely begun to
              explore the use cases for these previous gen models, yet we're
              already blazing ahead to ever more advanced systems. There's so
              little time for adjustment now, we are full speed ahead.
            </p>
            <p>
              When I was a kid we had VHS for many years before the first DVDs
              came out. Then there was a transition period where both formats
              were available, and people even got those VHS/DVD player combo
              machines. Basically taking our sweet time to switch over. When VHS
              was finally phased out, it was because it had become firmly "out
              of date". A while later came Blueray, a while later Netflix. What
              I mean is, it didn't all just happen at once.
            </p>
            <p>
              The speed of advancement happening in AI right now is like what
              would happen if VHS went to DVD then Bluray then Netflix all
              within the same year. It's that level of speed of advancement, how
              there's almost no time to take a breath and actually look at what
              we have so far. That there are ways of using even this basic 3.5
              model we still haven't fully explored, yet better models have
              already been released and the birth of full-blown AGI is just
              around the corner.
            </p>
            <p>
              I wonder how people will adjust. Obviously some are scared, or
              angry their jobs are at risk. On the other hand, it's an easy sell
              because of how user-friendly AI systems can be. It can't get any
              easier than "just tell it what you want". This technical
              revolution is not like having to teach grandpa how to use the
              internet. This time grandpa can literally just talk to his
              computer and it will do whatever he needs to without any learning
              curve.
            </p>
            <p>
              We are at a very special moment in human history. From now on, AI
              will be a permanent part of our existence, and will become even
              more pervasive and deeply integrated with us as time goes on.
            </p>

            <p>
              <b>
                How does it feel to be one of the last humans in history to
                experience life without AI?
              </b>
            </p>
            <DividerLine theme={colorTheme} />
          </section>

          <div className="footer-text">
            <span>
              Thanks for visiting! Made with React in Visual Studio Code and
              deployed with Netlify. 2024
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
