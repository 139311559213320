import React, { useRef, useEffect, useMemo } from "react";
import "./Metaballs.module.scss";

class LavaLamp {
  constructor(width, height, numMetaballs, colorStart, colorEnd, ctx) {
    this.step = 5;
    this.width = width;
    this.height = height;
    this.wh = Math.min(width, height);
    this.sx = Math.floor(this.width / this.step);
    this.sy = Math.floor(this.height / this.step);
    this.paint = false;
    this.metaFill = createRadialGradient(
      width,
      height,
      width,
      colorStart,
      colorEnd,
      ctx
    );
    this.plx = [0, 0, 1, 0, 1, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 0];
    this.ply = [0, 0, 0, 0, 0, 0, 1, 0, 0, 1, 1, 1, 0, 1, 0, 1];
    this.mscases = [0, 3, 0, 3, 1, 3, 0, 3, 2, 2, 0, 2, 1, 1, 0];
    this.ix = [1, 0, -1, 0, 0, 1, 0, -1, -1, 0, 1, 0, 0, 1, 1, 0, 0, 0, 1, 1];
    this.grid = [];
    this.balls = [];
    this.iter = 0;
    this.sign = 1;

    // init grid
    for (var i = 0; i < (this.sx + 2) * (this.sy + 2); i++) {
      this.grid[i] = new Point(
        (i % (this.sx + 2)) * this.step,
        Math.floor(i / (this.sx + 2)) * this.step
      );
    }

    // create metaballs
    for (var k = 0; k < numMetaballs; k++) {
      this.balls[k] = new Ball(this);
    }
  }

  computeForce(x, y, idx) {
    var force;
    var id = idx || x + y * (this.sx + 2);

    if (x === 0 || y === 0 || x === this.sx || y === this.sy) {
      force = 0.6 * this.sign;
    } else {
      force = 0;
      var cell = this.grid[id];
      if (!cell) return 0;
      var i = 0;
      var ball;
      while ((ball = this.balls[i++])) {
        force +=
          (ball.size * ball.size) /
          (-2 * cell.x * ball.pos.x -
            2 * cell.y * ball.pos.y +
            ball.pos.magnitude +
            cell.magnitude);
      }
      force *= this.sign;
    }
    this.grid[id].force = force;
    return force;
  }

  marchingSquares(next, ctx) {
    var x = next[0];
    var y = next[1];
    var pdir = next[2];
    var id = x + y * (this.sx + 2);

    if (this.grid[id].computed === this.iter) {
      return false;
    }
    var dir,
      mscase = 0;

    // neighbors force
    for (var i = 0; i < 4; i++) {
      var idn = x + this.ix[i + 12] + (y + this.ix[i + 16]) * (this.sx + 2);
      var force = this.grid[idn].force;
      if (
        (force > 0 && this.sign < 0) ||
        (force < 0 && this.sign > 0) ||
        !force
      ) {
        // compute force if not in buffer
        force = this.computeForce(
          x + this.ix[i + 12],
          y + this.ix[i + 16],
          idn
        );
      }
      if (Math.abs(force) > 1) mscase += Math.pow(2, i);
    }
    if (mscase === 15) {
      // inside
      return [x, y - 1, false];
    } else {
      // ambiguous cases
      if (mscase === 5) dir = pdir === 2 ? 3 : 1;
      else if (mscase === 10) dir = pdir === 3 ? 0 : 2;
      else {
        // lookup
        dir = this.mscases[mscase];
        this.grid[id].computed = this.iter;
      }
      // draw line
      var ix =
        this.step /
        (Math.abs(
          Math.abs(
            this.grid[
              x +
                this.plx[4 * dir + 2] +
                (y + this.ply[4 * dir + 2]) * (this.sx + 2)
            ].force
          ) - 1
        ) /
          Math.abs(
            Math.abs(
              this.grid[
                x +
                  this.plx[4 * dir + 3] +
                  (y + this.ply[4 * dir + 3]) * (this.sx + 2)
              ].force
            ) - 1
          ) +
          1);
      ctx.lineTo(
        this.grid[
          x + this.plx[4 * dir] + (y + this.ply[4 * dir]) * (this.sx + 2)
        ].x +
          this.ix[dir] * ix,
        this.grid[
          x +
            this.plx[4 * dir + 1] +
            (y + this.ply[4 * dir + 1]) * (this.sx + 2)
        ].y +
          this.ix[dir + 4] * ix
      );
      this.paint = true;
      // next
      return [x + this.ix[dir + 4], y + this.ix[dir + 8], dir];
    }
  }

  update() {
    // Logic to update the positions of metaballs
  }

  draw(ctx) {
    var i = 0,
      ball;
    while ((ball = this.balls[i++])) {
      ball.move();
    }
    // reset grid
    this.iter++;
    this.sign = -this.sign;
    this.paint = false;
    ctx.fillStyle = this.metaFill;
    ctx.beginPath();
    // compute metaballs
    i = 0;
    while ((ball = this.balls[i++])) {
      // first cell
      var next = [
        Math.round(ball.pos.x / this.step),
        Math.round(ball.pos.y / this.step),
        false,
      ];
      // marching squares
      do {
        next = this.marchingSquares(next, ctx);
      } while (next);
      // fill and close path
      if (this.paint) {
        ctx.fill();
        ctx.closePath();
        ctx.beginPath();
        this.paint = false;
      }
    }
  }

  updateColor(newStartColor, newEndColor, ctx) {
    this.metaFill = createRadialGradient(
      this.width,
      this.height,
      this.width,
      newStartColor,
      newEndColor,
      ctx
    );
  }
}

class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.magnitude = x * x + y * y;
    this.computed = 0;
    this.force = 0;
  }
  add(p) {
    return new Point(this.x + p.x, this.y + p.y);
  }
}

class Ball {
  constructor(parent, x, y) {
    var min = 0.3;
    var max = 0.8;
    this.vel = new Point(
      (Math.random() > 0.5 ? 1 : -1) * (0.1 + Math.random() * 0.1),
      (Math.random() > 0.5 ? 1 : -1) * (0.1 + Math.random() * 0.1)
    );
    this.pos = new Point(
      x !== undefined
        ? x
        : parent.width * 0.2 + Math.random() * parent.width * 0.6,
      y !== undefined
        ? y
        : parent.height * 0.2 + Math.random() * parent.height * 0.6
    );
    this.size =
      parent.wh / 10 + (Math.random() * (max - min) + min) * (parent.wh / 10);
    this.width = parent.width;
    this.height = parent.height;
  }

  move() {
    // bounce borders
    if (this.pos.x >= this.width - this.size) {
      if (this.vel.x > 0) this.vel.x = -this.vel.x;
      this.pos.x = this.width - this.size;
    } else if (this.pos.x <= this.size) {
      if (this.vel.x < 0) this.vel.x = -this.vel.x;
      this.pos.x = this.size;
    }

    if (this.pos.y >= this.height - this.size) {
      if (this.vel.y > 0) this.vel.y = -this.vel.y;
      this.pos.y = this.height - this.size;
    } else if (this.pos.y <= this.size) {
      if (this.vel.y < 0) this.vel.y = -this.vel.y;
      this.pos.y = this.size;
    }

    // velocity
    this.pos = this.pos.add(this.vel);
  }
}

const DEFAULT_SETTINGS = {
  numLavaBalls: 4,
  lavaColor2: "#00ffff",
  lavaColor1: "#ff00ff",
};

var createRadialGradient = function (w, h, r, c0, c1, ctx) {
  var gradient = ctx.createRadialGradient(w / 1, h / 1, 0, w / 1, h / 1, r);
  gradient.addColorStop(0, c0);
  gradient.addColorStop(1, c1);
  return gradient;
};

const Metaballs = (props) => {
  const canvasRef = useRef(null);
  const wrapperRef = useRef(null);

  const lavaLampRef = useRef(null); // Ref for the LavaLamp instance

  const settings = useMemo(
    () => ({
      ...DEFAULT_SETTINGS,
      lavaColor1: props.theme.accent1,
      lavaColor2: props.theme.accent2,
    }),
    [props.theme]
  );

  useEffect(() => {
    // This effect updates the color theme of the LavaLamp instance
    // It runs every time the theme changes, without reinitializing the instance
    if (lavaLampRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      lavaLampRef.current.updateColor(
        settings.lavaColor1,
        settings.lavaColor2,
        ctx
      );
    }
  }, [settings.lavaColor1, settings.lavaColor2]);

  useEffect(() => {
    const canvas = canvasRef.current;
    // Define a function to resize the canvas to fit the wrapper
    const resizeCanvas = () => {
      if (wrapperRef.current) {
        const { width, height } = wrapperRef.current.getBoundingClientRect();
        canvas.width = width;
        canvas.height = height;
        return { width, height }; // Return the new dimensions
      }
      return { width: 0, height: 0 }; // Fallback dimensions
    };

    // Initialize LavaLamp instance once
    if (!lavaLampRef.current) {
      const screen = resizeCanvas();

      const ctx = canvas.getContext("2d");

      lavaLampRef.current = new LavaLamp(
        canvas.width,
        canvas.height,
        5,
        DEFAULT_SETTINGS.lavaColor1,
        DEFAULT_SETTINGS.lavaColor2,
        ctx
      );
      const run = function () {
        requestAnimationFrame(run);
        ctx.clearRect(0, 0, screen.width, screen.height);
        lavaLampRef.current.draw(ctx);
      };

      run();
    }

    const handleResize = () => {
      resizeCanvas();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={wrapperRef} className="wrap">
      <canvas ref={canvasRef}></canvas>
    </div>
  );
};

export default Metaballs;
